import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBR from '../lang/pt-BR';
export var setupTranslations = function setupTranslations() {
  var i18nInstance = i18n.use(initReactI18next).init({
    resources: {
      ptBR: ptBR
    },
    lng: 'ptBR',
    fallbackLng: 'ptBR',
    interpolation: {
      escapeValue: false
    }
  });
  return i18nInstance;
};