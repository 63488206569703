/* eslint-disable max-len */
export var ptBR = {
  translation: {
    // Atoms
    TRY_AGAIN: 'Tentar novamente',
    LOADING: 'Carregando',
    APP_NAME: 'Rastreia Fácil',
    SHIP_UNTIL: 'Entrega até',
    UNTIL: 'Até',
    DELIVERED_DAY: 'Entregue dia',
    DELIVERED: 'Entregue',
    REFERENCE: 'Referência',
    QUANTITY: 'Quantidade',
    PRICE: 'Preço',
    TRACKING_CODE: 'Código de rastreio',
    FB_MESSENGER: 'Facebook Messenger',
    TELEGRAM: 'Telegram',
    WHATSAPP: 'WhatsApp',
    EMAIL: 'E-mail',
    NOT_NOTIFY: 'Não quero ser notificado',
    BACK: 'Voltar',
    EDIT: 'Editar',
    BAD: 'Ruim',
    VERY_BAD: 'Muito ruim',
    NEUTRAL: 'Normal',
    GOOD: 'Bom',
    VERY_GOOD: 'Muito bom',
    ACCESS_LINK: 'Acessar',
    CSAT_VERY_BAD: 'Muito insatisfeito',
    CSAT_BAD: 'Insatisfeito',
    CSAT_NEUTRAL: 'Neutro',
    CSAT_GOOD: 'Satisfeito',
    CSAT_VERY_GOOD: 'Muito satisfeito',
    CONNECT: 'Conectar',
    REGISTER: 'Registrar',
    CONFIRM: 'Confirmar',
    DESCRIPTION: 'Descrição',
    NOTIFICATION_CHANNEL: 'Canal de notificação',
    SEE_ALL_PACKAGES: 'Ver todos os pacotes',
    DELIVERY_DATA: 'Dados da entrega',
    PACKAGE_PRODUCTS: 'Itens do pacote',
    SELECT_CHANNEL: 'Selecione um canal',
    COURIER: 'Transportadora',
    TRACKING_NUMBER: 'Número do rastreio',
    TRACKING_WITH_CODE: 'rastreio :code',
    DELIVERY_ESTIMATION: 'Previsão de entrega',
    INVOICE_LINK: 'Nota fiscal',
    TRACKING_LINK: 'Link de rastreio',
    DELIVERY_NOTIFICATIONS: 'Notificações de entrega',
    TEXT_MESSAGE: 'Mensagem de texto',
    CELL_PHONE_WITH_DDD: 'Telefone com DDD',
    CELL_PHONE_WITH_DDD_PLACEHOLDER: 'Ex.: 41 99999-9999',
    YOUR_EMAIL: 'Seu e-mail',
    EMAIL_PLACEHOLDER: 'Ex.: username@email.com',
    NOT_BE_NOTIFIED: 'Não quero ser notificado',
    RECEIVE_NOTIFICATIONS: 'Receber notificações',
    SELECT_OTHER_CHANNEL: 'Selecionar outro canal',
    WAITING_NEW_EVENT: 'Aguardando atualização',
    CLOSE: 'Fechar',
    ACTION_PICKED: 'Já retirei',
    ACTION_NEED_HELP: 'Preciso de ajuda',
    ACTION_RETURN_OR_EXCHANGE: 'Preciso trocar ou devolver',
    ACTION_NOT_RECEIVED: 'Não recebi',
    ACTION_RECEIVED: 'Já recebi',
    ACTION_CHANGE_CHANNEL: 'Selecionar outro canal',
    ACTION_SELECT_CHANNEL: 'Selecionar canal',
    ACTION_ACCEPT_NEW_ESTIMATED_DELIVERY_DATE: 'Ok, vou aguardar',
    ACTION_REFUSE_NEW_ESTIMATED_DELIVERY_DATE: 'Recusar',
    ACTION_ACCEPT_RETENTION: 'Sim, aceito o vale-compra',
    ACTION_ACCEPT_RETENTION_WITH_BONUS: 'Oba, aceito o vale + bônus',
    ACTION_REFUSE_RETENTION: 'Não, quero outra solução',
    POLICY_TERMS_LABEL: 'Política de Privacidade',
    USE_TERMS_LABEL: 'Termos de Uso',
    SHOW_MORE: 'Ver todas as atualizações ({{events_length}})',
    SHOW_LESS: 'Ver menos',
    EVENT_CHANNEL_SELECTED: 'Canal selecionado',
    EVENT_CHANNEL_SMS_SELECTED: 'Você receberá mensagens no seu celular {{phone}} referente às atualizações do seu pedido',
    EVENT_CHANNEL_MESSENGER_SELECTED: 'Você receberá novas atualizações do seu pedido diretamente no Facebook Messenger.',
    EVENT_NO_CHANNEL_SELECTED: 'Você escolheu não receber notificações de atualizações do seu pedido.',
    NO_NOTIFICATIONS: 'Sem notificações',
    TRACKING: 'Rastreio',
    TRACKING_JOURNEY: 'Jornada da Entrega',
    PACKAGE: 'Pacote {{PACKAGE_INDEX}}',
    AND: 'e',
    WAITING_MORE_INFO: 'Aguardando mais informações',
    SEND: 'Enviar',
    COMMENT: 'Comentário',
    OPTIONAL: 'Opcional',
    SEND_FEEDBACK: 'Enviar avaliação',
    RATE_YOUR_EXPERIENCE: 'Avaliação',
    FEEDBACK_ERROR_MESSAGE: 'Oops, houve um erro! Tente novamente mais tarde.',
    DELIVERY_CONFIRMATION: 'você recebeu o seu pedido?',
    DELIVERY_CONFIRMATION_ERROR: 'entrega não confirmada 😟',
    DELIVERY_CONFIRMATION_DESCRIPTION: 'Ao confirmar o recebimento, você nos ajuda a melhorar ainda mais a experiência de acompanhamento das suas entregas',
    DELIVERY_CONFIRMATION_ERROR_DESCRIPTION: 'Ocorreu um erro e não conseguimos concluir a operação. Por favor, tente novamente para confirmar sua entrega.',
    RECEIVED_ORDER: 'sim, recebi meu pedido',
    DENY_AND_GO_BACK: 'não, voltar',
    RETENTION_WITH_BONUS_TITLE: 'que tal ter um bônus na sua próxima compra?',
    RETENTION_WITH_BONUS_DESCRIPTION: 'Ao garantir o vale-compra, você também ganha um bônus! Fácil, né?',
    GENERIC_ERROR_TITLE: 'Desculpe! 😟',
    GENERIC_ERROR_DESCRIPTION: 'Não foi possível concluir a ação solicitada. Por favor, tente novamente.',
    MAXLENGTH_ERROR: 'Você atingiu o máximo de caracteres!',
    OFFER_HELP: 'Estamos aqui para te ajudar 😃',
    OFFER_HELP_DESCRIPTION: 'Por favor, preencha as informações abaixo para facilitar o atendimento. Em breve, nosso time de suporte entrará em contato com você.',
    COUPON: 'cupom',
    VOUCHER: 'vale-compra',
    COUPON_OFFER: 'Que tal um cupom de desconto?',
    COUPON_DESCRIPTION: 'Que legal, você acaba de ganhar um cupom de desconto exclusivo para a sua próxima compra. Basta copiar o código abaixo e adicioná-lo ao carrinho.',
    COUPON_DESCRIPTION_WITHOUT_SIMILAR_PRODUCTS: 'Poxa! No momento não possuimos nenhum produto similar a sua compra, mas você acaba de ganhar um cupom de desconto exclusivo para a sua próxima compra! Basta copiar o código abaixo e adicioná-lo ao carrinho.',
    COPY_AND_GO_SHOP: 'Copiar e ir pra loja',
    COUPON_RULES_TITLE: 'Regras e informações',
    BONUS_VALUE: ' + 5%',
    RECOMMENDED: 'Produtos que você pode gostar! 😀',
    UTM_SOURCE: 'aftersale',
    UTM_CAMPAIGN: 'jornada-de-entregas',
    // Messages
    WE_KEEP_YOU_UPDATED: 'Escolha seu canal de comunicação favorito e vamos te informar sobre todas as etapas de seu pedido.',
    NOT_DISTURB_CONFIRMATION: 'Tem certeza que não quer receber nenhuma notificação relacionada à entrega dos seus produtos?',
    WAITING_NEW_EVENT_MESSAGE: 'Seu rastreio será disponibilizado em breve. Por favor, aguarde.',
    POLICY_TERMS_MESSAGE: 'Ao continuar você aceita os',
    THANKS_FOR_FEEDBACK: 'Obrigado pelo feedback!',
    HOW_YOUR_EXPERIENCE: 'Conta pra gente, você ficou satisfeito com o acompanhamento desta entrega?',
    TELL_ABOUT_YOUR_EXPERIENCE: 'Nos conte como foi sua experiência',
    TELL_MORE_IF_YOU_WANT: 'Se desejar, nos conte como podemos melhorar',
    TELL_MORE_ABOUT_SOLICITATION: 'Insira os detalhes da sua solicitação...',
    MULTIPLE_PACKAGES_TRACKING: 'Foi necessário separar em {{PACKAGE_QUANTITY}} pacotes para que o seu pedido chegue o mais rápido possível no seu endereço.',
    // Aria labels
    ARIA_CLOSE_FEEDBACK_FORM: 'Fechar o formulário de avaliação de experiência',
    ARIA_LABEL_CHECK_ICON: 'Ícone que representa um sinal de sucesso',
    ARIA_LABEL_VERY_SAD_FACE: 'Ícone que representa um rosto muito triste',
    ARIA_LABEL_SAD_FACE: 'Ícone que representa um rosto triste',
    ARIA_LABEL_NEUTRAL_FACE: 'Ícone que representa um rosto sem emoção',
    ARIA_LABEL_HAPPY_FACE: 'Ícone que representa um rosto feliz',
    ARIA_LABEL_VERY_HAPPY_FACE: 'Ícone que representa um rosto muito feliz',
    ARIA_LABEL_TIMES: 'Ícone que representa um xis',
    // Asks
    HOW_TO_BE_NOTIFIED: 'Como você gostaria de ser notificado?',
    RECEIVE_UPDATES_WITH_WHATSAPP: 'Receber as atualizações pelo WhatsApp?',
    RECEIVE_UPDATES_WITH_FB_MESSENGER: 'Receber as atualizações pelo Facebook Messenger?',
    RECEIVE_UPDATES_WITH_SMS: 'Receber as atualizações por mensagem de texto?',
    // Survey
    SURVEY_DESCRIPTION: 'O que você achou dos produtos apresentados?'
  }
};
export default ptBR;